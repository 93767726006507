import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from 'react-toastify';
import * as Yup from "yup";

const schema = Yup.object().shape({
  contractAddress: Yup.string().trim().required(),
  collateralAmount: Yup.number().integer().positive().required(),
  SGTAmount: Yup.number().integer().positive().required(),
  dollarQuotation: Yup.string().trim().required(),
});

const AddCollateral = ({ isOpen, setIsOpen, addCollateral }) => {
  return (
    <Formik
      validationSchema={schema}
      onSubmit={(evt) => {
        const { contractAddress, collateralAmount, SGTAmount, dollarQuotation } = evt;

        addCollateral(contractAddress, collateralAmount, SGTAmount, dollarQuotation);

        setIsOpen(false);

        toast("Collateral adicionado!", { type: "success" });
      }}
      initialValues={{
        contractAddress: "",
        collateralAmount: 0,
        SGTAmount: 0,
        dollarQuotation: 0,
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Collateral</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form id="addCollateral" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Contract Address</Form.Label>
                <Form.Control
                  type="text"
                  name="contractAddress"
                  value={values.contractAddress}
                  onChange={handleChange}
                  isValid={touched.contractAddress && !errors.contractAddress}
                  isInvalid={!!errors.contractAddress}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Collateral Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="collateralAmount"
                  value={values.collateralAmount}
                  onChange={handleChange}
                  isValid={touched.collateralAmount && !errors.collateralAmount}
                  isInvalid={!!errors.collateralAmount}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>SGT Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="SGTAmount"
                  value={values.SGTAmount}
                  onChange={handleChange}
                  isValid={touched.SGTAmount && !errors.SGTAmount}
                  isInvalid={!!errors.SGTAmount}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Dollar Quotation</Form.Label>
                <Form.Control
                  type="text"
                  name="dollarQuotation"
                  value={values.dollarQuotation}
                  onChange={handleChange}
                  isValid={touched.dollarQuotation && !errors.dollarQuotation}
                  isInvalid={!!errors.dollarQuotation}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsOpen(false)}>
              Close
            </Button>
            <Button type="submit" form="addCollateral" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

export default AddCollateral;
