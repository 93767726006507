import { ethers } from "ethers";
import { useState } from "react";
import { toast } from "react-toastify";
import { contractAbi } from "./abi";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from "react-bootstrap/DropdownButton";


const ConnectionHeader = ({ provider, contract, address, setDisabled }) => {
  const [message, setMessage] = useState("");

  const [ethBalance, setEthBalance] = useState("");
  const [sgtBalance, setSGTBalance] = useState("");
  const [symbol, setSymbol] = useState("");
  const [owner, setOwner] = useState("");

  const items = process.env.REACT_APP_ADDRESSES.split(" ").reduce((acc, cur) => {
    const splitted = cur.split(":");
    acc.push([splitted[0], splitted[1]])
    return acc;
  }, []);

  async function connect(contractAddress) {
    try {
      if (!window.ethereum) {
        toast("Erro ao conectar!", { type: "error" });
        return setMessage("No metamask installed");
      }
      setMessage("Trying to connect..");

      //MetaMask requires requesting permission to connect users accounts
      await window.ethereum.request({ method: "eth_requestAccounts" });

      provider.current = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.current.getSigner();
      //Get the address of the current metamask wallet
      address.current = await signer.getAddress();

      setMessage(address.current);

      contract.current = new ethers.Contract(contractAddress, contractAbi, signer);

      const ethBalance = await provider.current.getBalance(address.current);
      setEthBalance(ethers.utils.formatEther(ethBalance));

      const SGTBalance = await contract.current.balanceOf(address.current);
      setSGTBalance(ethers.utils.formatEther(SGTBalance));

      const Symbol = await contract.current.symbol();
      setSymbol(Symbol);

      const owner = await contract.current.owner();
      setOwner(owner);

      const _disabled =
        !provider.current || !contract.current || !address.current;
      setDisabled(_disabled);

      toast("Conectado!", { type: "success" });
    } catch (error) {
      console.log(error);
      toast("Erro ao conectar!", { type: "error" });
    }
  }

  return (
    <>
      <p>Your Address: {message}</p>
      <hr />
      <p>Symbol: {symbol}</p>
      <p>Balance(ETH): {ethBalance}</p>
      <p>Balance(SGT): {sgtBalance}</p>
      <p>Contract Owner: {owner}</p>
      <hr />
      <p>
        <DropdownButton id="dropdown-connect" title="Connect">
            {items.map((value, i) => (
                <Dropdown.Item
                key={value[0]}
                onClick= {() => connect(value[1])}
                >
                {value[0]}
                </Dropdown.Item>
            ))}
        </DropdownButton>
      </p>
      <hr />
    </>
  );
};

export default ConnectionHeader;
